html{
  background:#fff;
}
body{
  font-family: 'Roboto', sans-serif;
}

.logo {
  margin-left: auto;
  padding-top:130px;
  height: auto;
  text-align:right;
    img{
    width: 550px;
  }
}

.container{
  padding-left:130px;
  padding-right:130px;
}
h1{
  font-size: 150px;
  font-weight: 400;
  color: #0033CC;
  max-width: 1380px;
  margin-bottom: 80px;
  margin-top: 40px;
  line-height: 1.1;
}
.subtext{
  text-transform: uppercase;
  color:#0033CC;
  font-size:46px;
  letter-spacing:8px;
}
.content-container{
  display:flex;
  justify-content: space-between;
  padding-bottom:40px;
}
.text-container{
  display:flex;
  flex-direction: column;
  align-items:center;
  margin-right:40px;
    p{
      font-size:30px;
      line-height:1.4;
      max-width: 800px;
      margin-bottom:40px;
      color:#0D1B36;
      font-weight:300;
    }
}
.thumbnail-container{
  display: flex;
  align-items: flex-end;
}
.video-play{
  cursor:pointer;
  border: 0;
  padding:0;
  margin:0;
}
.modal-top{
  height: 110px;
  display:flex;
  align-items:center;
  justify-content: flex-end;
  padding-right: 50px;
  cursor:pointer;
    #close-btn{
      cursor:pointer;
        .svg-close{
          width:35px;
          height:35px;
          path{
            fill: #1768f8;
          }
        }
    }
    button{
      border: 0;
      background: none;
    }
}
.video-js .vjs-control-bar {
  display: none;
  width: 100%;
  top: 0!important;
}
.content {
  min-height: 100vh;
  min-height:100%;
  height:100%;
  background:white;
  position:relative;
  z-index:0;
}

#modal-container {
  position:fixed;
  display:table;
  height:100%;
  width:100%;
  top:0;
  left:0;
  transform:scale(0);
  z-index:1;
  &.three {
    z-index:0;
    transform:scale(1);
    .modal-background {
      background:rgba(0,0,0,.6);
      .modal {
        animation: moveUp .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
    + .content {
      z-index:1;
      animation: slideUpLarge .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }
    &.out {
      .modal-background {
        .modal {
          animation: moveDown .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
      }
      + .content {
        min-height: 100vh;
        animation: slideDownLarge .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
  }
  .modal-background {
    display:table-cell;
    background:rgba(0,0,0,.8);
    text-align:center;
    vertical-align:middle;
    .modal {
      width:100%;
      height:100%;
      background:white;
      display:inline-block;
      position:relative;
    }
  }
}

@keyframes slideUpLarge {
  0% {
    transform:translateY(0%);
  }
  100% {
    transform:translateY(-100%);
  }
}

@keyframes slideDownLarge {
  0% {
    transform:translateY(-100%);
  }
  100% {
    transform:translateY(0%);
  }
}

@keyframes moveUp {
  0% {
    transform:translateY(150px);
  }
  100% {
    transform:translateY(0);
  }
}

@keyframes moveDown {
  0% {
    transform:translateY(0px);
  }
  100% {
    transform:translateY(150px);
  }
}






@media only screen and (max-width: 800px) {
  .text-container {
    margin-right:0;
  }
  body{
    height:auto;
  }
  .container {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom:40px;
  }
  .intro-text{
    margin-top:40px;
  }
  h1 {
    font-size: 56px;
  }
  .logo {
    padding-top: 20px;
    text-align: left;
    img{
      width:190px
    }
  }

}


@media only screen and (max-width: 1200px) {
  .container {
    padding-left: 70px;
    padding-right: 70px;
}
  body{
    height:auto;
  }
  .content-container {
    display: flex;
      .text-container{
        p{
          font-size: 16px;
          margin-bottom: 20px;

        }
      }
  .thumbnail-container{
    margin-left: 40px;
  }
  }
  .intro-text{
    h1{
      font-size: 75px;
      max-width: 610px;
    }
    .sub-text{
      letter-spacing: 4px;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .thumbnail-container {
    align-items: center;
    justify-content: center;
}
}

@media only screen and (max-width: 2000px) {
  .logo {
    padding-top:50px;
      img{
      width: 250px;
    }
  }
  .subtext {
    color: #0033CC;
    font-size: 18px;
  }
  h1 {
    font-size: 80px;
    max-width: 750px;
    margin-top:10px;
    margin-bottom:50px;
  }
  .text-container p {
    font-size: 18px;
  }
  .modal-top {
    height: 60px;
    cursor:pointer;
    #close-btn{
      .svg-close {
      width: 25px!important;
      height: 25px!important;
      cursor:pointer;
    }
  }
  }
  .text-container {
    justify-content: center;
  }
}


@media only screen and (max-width: 1000px) {
  .content-container{
    flex-direction: column;
      .thumbnail-container {
        margin-left: 0;
        margin-top:40px;
      }
  }

}